import { Controller } from '@hotwired/stimulus';
// @ts-ignore
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['plateList'];

  declare plateListTarget: HTMLUListElement;
  declare vehiclesValue: Vehicle[];

  static values = {
    vehicles: Array,
    route: String,
  };

  declare routeValue: string;

  connect() {
    this.addPlates();
  }

  addPlates() {
    this.vehiclesValue.forEach((vehicle) => {
      this.plateListTarget.append(this.plateComponent(vehicle));
    });
  }

  filter(e: InputEvent) {
    const target = e.target as HTMLInputElement;
    this.validateInput(target);

    Array.from(this.plateListTarget.children).forEach((li) => {
      if (
        li
          .getAttribute('data-plate-value')
          ?.includes(target.value.replace('-', ''))
      ) {
        li.classList.remove('hidden');
      } else {
        li.classList.add('hidden');
      }
    });
  }

  select(e: MouseEvent) {
    const target = e.currentTarget as HTMLLIElement;
    const plate = target.getAttribute('data-plate-value');

    Turbo.visit(`${this.routeValue}/${plate}?type=new_work_order`, {
      frame: 'vehicle_details',
    });

    document.dispatchEvent(new CustomEvent('autodeal:vehicle-selected'));
  }

  plateComponent({ plate, brand, model, color }: Vehicle): HTMLLIElement {
    const li : HTMLLIElement = document.createElement('li');

    li.id = plate;

    li.setAttribute(
      'data-action',
      'click->vehicles--plate-palette#select click->command-palette#close'
    );
    li.setAttribute('data-plate-value', plate);

    li.className =
      'flex items-center justify-between cursor-pointer select-none px-4 py-2 hover:bg-blue-600 hover:text-white group';

    li.insertAdjacentHTML(
      'beforeend',
      `
                    <span class="text-bold text-base">
                        ${[plate.slice(0, 3), '-', plate.slice(3)].join('')}
                    </span>
                    <span class="text-xs text-gray-500 group-hover:text-gray-300">
                        ${brand} (${model} - ${color})
                    </span>
        `
    );
    return li;
  }

  validateInput(inputElement: HTMLInputElement) {
    let inputValue = inputElement.value
      .replace(/[^a-zA-Z0-9]/g, '')
      .toUpperCase();

    if (inputValue.length > 3) {
      inputValue =
        inputValue.substring(0, 3) + '-' + inputValue.substring(3, 6);
    }

    inputElement.value = inputValue;
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['backdrop', 'container', 'dialog'];

  declare backdropTarget: HTMLElement;
  declare containerTarget: HTMLElement;
  declare dialogTarget: HTMLElement;

  connect() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }
  initialState() {
    this.backdropTarget.classList.add('animate-opacity-in');
    this.dialogTarget.classList.add('animate-dialog-in');
  }
  open() {
    this.containerTarget.classList.remove('hidden');
    this.initialState();
    setTimeout(() => {
      this.backdropTarget.classList.remove('animate-opacity-in');
      this.dialogTarget.classList.remove('animate-dialog-in');
    }, 250);
  }
  close() {
    this.backdropTarget.classList.add('animate-opacity-out');
    this.dialogTarget.classList.add('animate-dialog-out');
    setTimeout(() => {
      this.containerTarget.classList.add('hidden');
      this.backdropTarget.classList.remove('animate-opacity-out');
      this.dialogTarget.classList.remove('animate-dialog-out');
    }, 200);
  }
  backdropClose(event: MouseEvent) {
    if (event.currentTarget !== event.target) return;
    this.close();
  }
}

import {type ActionEvent, Controller} from '@hotwired/stimulus';
import anime from 'animejs';

export default class extends Controller {
  static targets = ['backdrop', 'container', 'palette'];

  declare backdropTarget: HTMLElement;
  declare containerTarget: HTMLElement;
  declare paletteTarget: HTMLElement;

  connect() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }

  open() {
    this.containerTarget.classList.remove('hidden');

    anime({
      targets: this.backdropTarget,
      easing: 'easeOutExpo',
      opacity: [0, 1],
      duration: 300,
    });

    anime({
      targets: this.paletteTarget,
      easing: 'easeOutExpo',
      scale: [0.95, 1],
      opacity: [0, 1],
      duration: 300,
    });
  }

  close() {
    anime({
      targets: this.backdropTarget,
      easing: 'easeInExpo',
      opacity: [1, 0],
      duration: 200,
    });

    anime({
      targets: this.paletteTarget,
      easing: 'easeInExpo',
      scale: [1, 0.95],
      opacity: [1, 0],
      duration: 200,
    });

    setTimeout(() => {
      this.containerTarget.classList.add('hidden');
    }, 200);
  }

  //** @param {Event} event */
  backdropClose(event : ActionEvent) {
    if (event.currentTarget !== event.target) return;
    this.close();
  }
}

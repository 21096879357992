import { Controller } from '@hotwired/stimulus';
import debounce from '@/util/debounce.js';
import formatPlate from '@/util/formatPlate.js';

interface State {
  vehicle: {
    [key: string]: string | null;
  };
  errors: string[];
  loading: boolean;
}
export default class extends Controller {
  static targets = ['input', 'inspection', 'insurance'];

  declare inputTargets: HTMLInputElement[];
  declare debouncedVehicleSearch: (...args: any) => void;

  plateMemo = '';
  state : State = {
    vehicle: {},
    errors: [],
    loading: false,
  };

  connect() {
    this.debouncedVehicleSearch = debounce((plate : string) => {
      const promises = [
        this.fetchVehicleInformation(plate),
        this.fetchInspectionExpiration(plate),
        this.fetchInsuranceExpiration(plate),
      ];

      Promise.allSettled(promises)
          .then(results => {
            results.forEach(({ status }) => {
              if (status === 'fulfilled') {
                this.state.loading = false;
              }
            });
          })
    }, 2000);
  }

  plateChange({ currentTarget } : Event) {
    this.state.loading = true;

    const target = currentTarget as HTMLInputElement;

    let plate = formatPlate(target.value);

    target.value = plate;

    if (plate.length === 7) this.debouncedVehicleSearch(plate);
  }

  setVehicleValues() {
    document.dispatchEvent(new CustomEvent('autodeal:input-fetch-success'));

    const vehicle = this.state.vehicle;

    this.inputTargets.forEach((input) => {
      const key = input.id.replace('vehicle_', '');
      const inputValue = vehicle[key];

      if (inputValue === null) return;

      input.value = vehicle[key];
    });
    this.plateMemo = vehicle.plate;
  }

  setInspectionExpiration() {
    const inspectionExpiration = document.getElementById(
      'vehicle_inspection_expiration'
    ) as HTMLInputElement;
    let type : string, title : string, message : string;

    if (this.state.vehicle.inspectionExpiration === null) {
      type = 'warn';
      message = 'No se encontró la fecha de vencimiento de la revisión técnica';
    } else {
      inspectionExpiration.value = this.state.vehicle.inspectionExpiration;

      if (new Date(this.state.vehicle.inspectionExpiration) < new Date()) {
        type = 'error';
        message = 'La revisión técnica está vencida';
      }
    }

    document.dispatchEvent(
      new CustomEvent('autodeal:notifications', {
        detail: {
          title: title || 'Revisión técnica',
          type: type || 'success',
          message: message || 'La revisión técnica fue recuperada con éxito',
        },
      })
    );
  }

  setInsuranceExpiration() {
    const insuranceExpiration = document.getElementById(
      'vehicle_insurance_expiration'
    ) as HTMLInputElement;

    let type : string, title : string, message : string;

    if (this.state.vehicle.insuranceExpiration === null) {
      type = 'warn';
      message = 'No se encontró la fecha de vencimiento del SOAT';
    } else {
      insuranceExpiration.value = this.state.vehicle.insuranceExpiration;

      if (new Date(this.state.vehicle.insuranceExpiration) < new Date()) {
        type = 'error';
        message = 'El SOAT esta vencido';
      }
    }

    document.dispatchEvent(
      new CustomEvent('autodeal:notifications', {
        detail: {
          title: title || 'SOAT',
          type: type || 'success',
          message:
            message || 'El vencimiento del SOAT fue recuperada con éxito',
        },
      })
    );
  }

  async fetchVehicleInformation(plate : string) : Promise<void> {
    try {
      const response = await fetch(`/api/external/vehicles/${plate}`);

      if (!response.ok) {
        const message = await response.json();

        throw new Error(JSON.stringify(message));
      }

      const { data } = await response.json();

      this.state.vehicle = { ...this.state.vehicle, ...data };
      this.state.loading = false;
      this.setVehicleValues();
    } catch (e) {
      const { message } = JSON.parse(e.message);

      this.state.errors = [...this.state.errors, message];

      document.dispatchEvent(new CustomEvent('autodeal:input-fetch-fail'));
    }
  }

  async fetchInspectionExpiration(plate : string) {
    try {
      const response = await fetch(
        `/api/external/vehicles/${plate}/technical_review_expiration`
      );

      if (!response.ok) {
        const message = await response.json();

        throw new Error(JSON.stringify(message));
      }

      const { data } = await response.json();
      this.state.vehicle.inspectionExpiration = data['inspection_expiration'];
      this.setInspectionExpiration();
    } catch (e) {
      const { message } = JSON.parse(e.message);

      this.state.errors = [...this.state.errors, message];
    }
  }

  async fetchInsuranceExpiration(plate : string) : Promise<void> {
    try {
      const response = await fetch(
        `/api/external/vehicles/${plate}/insurance_expiration`
      );

      if (!response.ok) {
        const message = await response.json();

        throw new Error(JSON.stringify(message));
      }

      const { data } = await response.json();
      this.state.vehicle.insuranceExpiration = data['insurance_expiration'];
      this.setInsuranceExpiration();
    } catch (e) {
      const { message } = JSON.parse(e.message);

      this.state.errors = [...this.state.errors, message];
    }
  }

  clearVehicleValues() {
    this.inputTargets.forEach((input) => {
      if (input.getAttribute('type') === 'date') {
        input.value = null;
        input.classList.remove(
          '!text-rose-600',
          '!ring-rose-600',
          '!bg-rose-100'
        );
      } else {
        input.value = '';
      }
    });
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['provinces', 'districts'];

  declare provincesTarget: HTMLSelectElement;
  declare districtsTarget: HTMLSelectElement;

  setSelectOptions(target: HTMLElement, data: string[]) {
    target.innerHTML = '';
    target.appendChild(document.createElement('option'));

    for (const province of data) {
      const option = document.createElement('option');
      option.value = province;
      option.text = province;
      target.appendChild(option);
    }
  }

  changeProvincesAndDistricts(event: InputEvent) {
    const target = event.target as HTMLSelectElement;
    const regionName = target.value;

    const result = fetch(
        `/api/v1/locations/regions/${regionName}/provinces`,
        { method: 'GET' })
      .then((response) => response.json())
      .then((data) => {
        this.setSelectOptions(this.provincesTarget, data);
        return fetch(`/api/v1/locations/provinces/${data[0]}/districts`);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    result
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((data) => {
        this.setSelectOptions(this.districtsTarget, data);
      });
  }

  changeDistricts(event: InputEvent) {
    const target = event.target as HTMLSelectElement;
    const provinceName = target.value;

    fetch(`/api/v1/locations/provinces/${provinceName}/districts`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        this.setSelectOptions(this.districtsTarget, data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
}

import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = [
    'clientList',
    'sidePanel',
    'emptyState',
    'selectedClient',
    'unselectedClient',
  ];

  declare clientListTarget: HTMLUListElement;
  declare sidePanelTarget: HTMLDivElement;
  declare emptyStateTarget: HTMLDivElement;
  declare selectedClientTarget: HTMLDivElement;
  declare unselectedClientTarget: HTMLDivElement;


  static values = {
    clients: Array,
    route: String,
  };

  declare clientsValue: Client[];
  declare routeValue: string;
  declare recentValidity: string | null;

  connect() {
    if (this.clientsValue.length > 0) {
      this.renderRecent();
    }

    this.recentValidity = localStorage.getItem('recent-validity');

    if (this.recentValidity !== null) {
      const recentValidity = new Date(
        localStorage.getItem('recent-validity')
      );
      const now = new Date();

      if (now.getTime() - recentValidity.getTime() > 86400000) {
        localStorage.removeItem('recent');
        localStorage.removeItem('recent-validity');
      }
    }
  }

  filter(e: InputEvent) {
    this.clientListTarget.textContent = '';

    const target = e.target as HTMLInputElement;

    if (target.value.length > 0) {
      const filteredClients = this.clientsValue.filter((client) => {
        const text =
          client.document_type === 'dni'
            ? `${client.first_name} ${client.last_name}`
            : client.business_name;
        return text.toLowerCase().includes(target.value.toLowerCase());
      });

      if (filteredClients.length === 0) {
        this.clientListTarget.parentElement.classList.add('hidden');
        this.sidePanelTarget.classList.add('hidden');
        this.emptyStateTarget.classList.remove('hidden');
      } else {
        this.clientListTarget.parentElement.classList.remove('hidden');
        this.emptyStateTarget.classList.add('hidden');
        filteredClients.forEach((client) =>
          this.clientListTarget.append(this.clientComponent(client))
        );
      }
    } else {
      this.renderRecent();
      this.emptyStateTarget.classList.add('hidden');
    }
  }

  select(e : MouseEvent) {
    const id = (e.currentTarget as HTMLElement).id;
    this.saveRecent(
      this.clientsValue.find(
        (client) => client.id === parseInt(id)
      )
    );
    this.selectedClientTarget.classList.remove('hidden');
    this.unselectedClientTarget.classList.add('hidden');

    Turbo.visit(`${this.routeValue}/${id}?type=mini-card`, {
      frame: 'client_details',
    });

    document
      .getElementById('vehicle_client_id')
      .setAttribute('value', id);
  }

  unselect() {
    this.selectedClientTarget.classList.add('hidden');
    this.unselectedClientTarget.classList.remove('hidden');
  }

  saveRecent(client: Client) {
    let recent = JSON.parse(localStorage.getItem('recent')) || [];
    const clientIndex = recent.findIndex(
      (recentClient: Client) => recentClient.id === client.id
    );

    if (clientIndex === -1) {
      recent.unshift(client);
      if (recent.length > 5) {
        recent.pop();
      }
    }
    localStorage.setItem('recent', JSON.stringify(recent));

    if (this.recentValidity === null) {
      localStorage.setItem('recent-validity', new Date().toISOString());
    }
  }

  renderPreview(e: MouseEvent) {
    const client = this.clientsValue.find(
      (client) => client.id === parseInt((e.currentTarget as HTMLLIElement).id)
    );

    this.sidePanelTarget.classList.remove('hidden');
    this.sidePanelTarget.textContent = '';
    this.sidePanelTarget.insertAdjacentHTML(
      'beforeend',
      `
            <div class="flex-none p-6 text-center">
              <div class="mx-auto h-16 w-16 rounded-full bg-indigo-100 grid place-items-center text-2xl">
                ${
                  client.document_type === 'dni'
                    ? `${client.first_name[0]}${client.last_name[0]}`
                    : client.business_name[0]
                }
              </div>
              <h2 class="mt-3 font-semibold text-gray-900">
                ${
                  client.document_type === 'dni'
                    ? `${client.first_name}${client.last_name}`
                    : client.business_name
                }
              </h2>
              <p class="text-sm leading-6 text-gray-500">DNI: ${client.document_number}</p>
            </div>
            <div class="flex flex-auto flex-col justify-between p-6">
              <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                <dt class="col-end-1 font-semibold text-gray-900">Teléfono</dt>
                <dd>${client.cellphone}</dd>
                <dt class="col-end-1 font-semibold text-gray-900">URL</dt>
                <dd class="truncate">
                  <a href="https://example.com" class="text-indigo-600 underline">https://example.com</a></dd>
                <dt class="col-end-1 font-semibold text-gray-900">Email</dt>
                <dd class="truncate"><a href="#" class="text-indigo-600 underline">${client.email}</a></dd>
              </dl>
            </div>
        `
    );
  }

  renderRecent() {
    let recent = JSON.parse(localStorage.getItem('recent')) || [];
    if (recent.length > 0) {
      this.clientListTarget.insertAdjacentHTML(
        'beforeend',
        '<li class="px-3 py-2 text-xs text-gray-400">Buscados recientemente</li>'
      );
      recent.forEach((client : Client) => {
        this.clientListTarget.append(this.clientComponent(client));
      });
    }

    this.clientListTarget.insertAdjacentHTML(
      'beforeend',
      '<li class="px-3 py-2 text-xs text-gray-400">Agregados recientemente</li>'
    );
    this.clientsValue
      .reverse()
      .slice(0, 10)
      .forEach((client) => {
        this.clientListTarget.append(this.clientComponent(client));
      });
  }

  clientComponent(client : Client) {
    const li = document.createElement('li');

    li.id = client.id.toString();
    li.setAttribute(
      'data-action',
      'click->clients--select-palette#select mouseover->clients--select-palette#renderPreview click->command-palette#close'
    );
    li.setAttribute(
      'data-value',
      client.document_type === 'dni'
        ? `${client.first_name} ${client.last_name}`
        : client.business_name
    );

    li.className =
      'group flex cursor-pointer select-none items-center rounded-md p-2 hover:bg-gray-100 hover:text-gray-900';

    li.insertAdjacentHTML(
      'beforeend',
      `
            <span class="w-7 h-7 group cursor-default select-none grid place-items-center rounded-full p-1 bg-indigo-50 text-xs ">
                ${
                  client.document_type === 'dni'
                    ? `${client.first_name[0]}${client.last_name[0]}`
                    : client.business_name[0]
                }
            </span>
            <span class="ml-3 flex-auto truncate">
                ${
                  client.document_type === 'dni'
                    ? `${client.first_name} ${client.last_name}`
                    : client.business_name
                }
            </span>
            <svg class="ml-3 hidden h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"/>
            </svg>
        `
    );
    return li;
  }
}

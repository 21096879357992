<script>
    export let question;
</script>
<div id="{question}" class='flex flex-col'>
    <div class='flex flex-col justify-start'>
        <ul data-role='options' class='grid grid-cols-1 justify-start gap-y-2'>
            a
        </ul>
        <div class='mt-4'>
      <span
              data-action='click->manager--reports--list#addOption'
              class='p-2 text-sm text-gray-400 cursor-pointer'>
        Agregar una opción
      </span>
        </div>
    </div>
</div>

<script lang="ts">
  import Question from '@/components/manager/reports/edit/Question.svelte';
  import { onMount, getContext } from 'svelte';
  import { sections, subscribe } from '@/stores/manager_reports_store';

  export let key, section, questions;

  $: total = 0;

  const { id, title, description } = section;
  const setFocusedItem = getContext('focusedItem');


  onMount(() => {
    subscribe((store) => (total = store.sections.length));
  });

  const handleChange = ({ currentTarget }) => {
    sections.edit(id, { [currentTarget.name]: currentTarget.value });
  };

  const handleClick = ({ currentTarget }) => setFocusedItem(currentTarget);
</script>


<li class='my-2.5'>
  <button
  type='button'
  class="relative w-full"
  on:click={handleClick}
  aria-label="Focus"
>
  <!-- Top header -->
  <div
    class="bg-indigo-400 w-full sm:w-[120px] rounded-t-lg text-center text-sm py-2 text-white"
  >
    Sección {key + 1} de {total}
  </div>
  <div class="bg-indigo-400 w-full h-2 sm:rounded-tr-lg"></div>
  <div class="relative overflow-hidden rounded-b-lg bg-white shadow-md">
    <div
      class="animate-opacity-in transition-all hidden absolute w-2 h-full bg-indigo-800"
    ></div>

    <div class="px-4 py-5 sm:p-6">
      <div data-role="section-title">
        <div class="relative mt-2">
          <input
            type="text"
            name="title"
            value={title}
            class="text-3xl peer block w-full border-0 bg-transparent py-1.5 text-gray-900 focus:ring-0 sm:leading-6"
            placeholder="Titulo"
            on:change={handleChange}
          />
          <div
            class="select-none absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
            aria-hidden="true"
          ></div>
        </div>
      </div>
      <div data-role="section-title">
        <div class="relative mt-2">
          <input
            type="text"
            name="description"
            value={description}
            class="text-sm peer block w-full border-0 bg-transparent py-1.5 text-gray-900 focus:ring-0 sm:leading-6"
            placeholder="Descripción del formulario"
            on:change={handleChange}
          />
          <div
            class="select-none absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
            aria-hidden="true"
          ></div>
        </div>
      </div>
    </div>
  </div>
</button>
</li>

{#if questions.length !== 0}
  {#each questions as question, i}
    <Question key={i} {question} />
  {/each}
{/if}

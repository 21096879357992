import {type ActionEvent, Controller} from '@hotwired/stimulus';
import Notifications from '@/components/shared/Notifications.svelte';
import { store } from '@/stores/notifications_store.js';

export default class extends Controller {
  initialize() {
    new Notifications({
      target: this.element,
    });
  }

  connect() {
    document.addEventListener('autodeal:notifications', (e: Event) => {
      const customEvent = e as CustomEvent;

      customEvent.stopImmediatePropagation();
      this.add(customEvent.detail);
    });
  }

  add({ type, title, message }: { type: string; title: string; message: string }) {
    store.add(type, title, message);
  }

  remove(event: ActionEvent) {
    const notificationContainer = (event.currentTarget as HTMLElement).closest(
      '.notification-container'
    );

    if (notificationContainer) {
      notificationContainer.remove();
    }
  }
}

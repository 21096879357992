import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  declare inputTargets: HTMLInputElement[];

  static values = {
    url: String,
  };

  declare urlValue: string;

  connect() {}

  async packageSelected({ target } : InputEvent) {
    try {
      const response = await this.fetchPackage((target as HTMLSelectElement).value);
      const { price, jobs } = await response.json();

      this.clearJobs();
      this.insertJobs(jobs);
      this.setPrice(price);
    } catch (error) {
      console.error(error);
    }
  }

  async fetchPackage(id: string) {
    return await fetch(`${this.urlValue}/${id}`, {
      headers: {
        Accept: 'application/json',
      },
    });
  }

  setPrice(price : number) {
    const priceInput = document.getElementById('work_order_estimated_cost') as HTMLInputElement;
    priceInput.value = price.toString();
  }

  insertJobs(jobs : string[]) {
    this.inputTargets.forEach((input, index) => {
      if (jobs[index] === undefined) return;

      input.value = jobs[index];
      input.parentElement?.classList.add('!bg-slate-100', 'dark:!bg-slate-800');
      input.setAttribute('name', 'from_package');
    });
  }

  clearJobs() {
    this.inputTargets.forEach((input) => {
      if (input.getAttribute('name') === 'from_package') {
        input.value = '';
        input.classList.remove('!bg-slate-100', 'dark:!bg-slate-800');
        input.setAttribute('name', 'work_order[jobs][]');
      }
    });
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'icon', 'type'];

  declare inputTarget: HTMLInputElement;
  declare iconTarget: HTMLSpanElement;
  declare typeTarget: HTMLSelectElement;

  connect() {
    document.addEventListener('autodeal:input-fetch-success', () =>
      this.documentInputSuccessState()
    );
    document.addEventListener('autodeal:input-fetch-fail', () =>
      this.documentInputErrorState()
    );
  }

  change({ currentTarget }: InputEvent) {
    const target = currentTarget as HTMLInputElement;
    const currentType = this.typeTarget.value ?? 'plate';
    const inputValueLength = target.value.length;

    if (
      (inputValueLength === 8 && currentType === 'dni') ||
      (inputValueLength === 11 && currentType === 'ruc')
    ) {
      this.documentInputSearchState();
    } else {
      this.documentInputClearState();
    }
  }

  changePlate({ currentTarget }: InputEvent) {
    const target = currentTarget as HTMLInputElement;
    const inputValueLength = target.value.length;

    if (inputValueLength === 7) {
      this.documentInputSearchState();
    } else {
      this.documentInputClearState();
    }
  }

  documentInputErrorState() {
    this.clearStyles(this.inputTarget);
    this.inputTarget.classList.add('!ring-rose-300', '!bg-rose-50');
    this.iconTarget.classList.replace('flex', 'hidden');
  }
  documentInputSearchState() {
    this.clearStyles(this.inputTarget);
    this.inputTarget.classList.add('!ring-sky-300', '!bg-sky-50');
    this.iconTarget.classList.replace('hidden', 'flex');
  }

  documentInputSuccessState() {
    this.clearStyles(this.inputTarget);
    this.inputTarget.classList.add('!ring-emerald-300', '!bg-emerald-50');
    this.iconTarget.classList.replace('flex', 'hidden');
  }

  documentInputClearState() {
    this.clearStyles(this.inputTarget);
    this.iconTarget.classList.replace('flex', 'hidden');
  }

  clearStyles(target: HTMLElement) {
    target.classList.remove(
      '!ring-rose-300',
      '!ring-sky-300',
      '!ring-emerald-300'
    );

    target.classList.remove('!bg-rose-50', '!bg-sky-50', '!bg-emerald-50');
  }
}

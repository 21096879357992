<script lang="ts">
  import { afterUpdate, onMount, getContext } from 'svelte';
  import {
    questions,
    types,
    focusedStore as focus, type Question,
  } from '@/stores/manager_reports_store.ts';

  import ShortAnswer from '@/components/manager/reports/edit/questions/ShortAnswer.svelte';
  import Paragraph from '@/components/manager/reports/edit/questions/Paragraph.svelte';
  import Multiple from '@/components/manager/reports/edit/questions/Multiple.svelte';

  export let question : Question;

  let questionTypes : string[];
  let currentType : string;
  const setFocusedItem = getContext<(target : HTMLElement) => {}>('focusedItem');

  $: questionComponent = {
    short_answer: ShortAnswer,
    paragraph: Paragraph,
    multiple: Multiple,
  };

  onMount(() => {
    currentType = question.type;
    types.subscribe((value : string[]) => (questionTypes = value));
  });

  afterUpdate(() => {
    // TODO: correct morph
    if (question.type === 'multiple') {
      questions.edit(question.id, { options: [] });
    }
  });

  const handleTitleChange = ({ currentTarget }) => {
    questions.edit(question.id, { question: currentTarget.value });
  };

  const handleTypeChange = (e) => {
    questions.edit(question.id, { type: e.target.value });
  };

  const handleClick = ({ currentTarget }) => setFocusedItem(currentTarget);
</script>

<li class='select-none relative rounded-lg bg-white shadow my-4'>
  <button
    on:click={handleClick}
    type="button"
    class="w-full"
  >
    <span class="absolute top-0 w-full flex justify-center">
      <svg
        class="hover:scale-110 transition duration-300 text-gray-400 w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
        ><path
          fill="none"
          stroke="currentColor"
          d="M3 5.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0Zm5 0a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0Zm5 0a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0Zm-10 4a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0Zm5 0a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0Zm5 0a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0Z"
        /></svg
      >
    </span>

    <span
      class="rounded-l-lg animate-opacity-in transition-all hidden absolute w-2 h-full bg-indigo-400"
    ></span>

    <div class="px-4 py-5 sm:p-6">
      <div
        class="flex flex-col sm:flex-row justify-between mb-5 animate-opacity-in"
      >
        <div class="flex-1">
          <div class="relative">
            <label>
              <input
                type="text"
                value={question.question}
                class="peer w-full lg:w-[350px] block border-0 bg-white py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Pregunta sin titulo"
                on:input={handleTitleChange}
              />
            </label>
            <div
              class="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
              aria-hidden="true"
            ></div>
          </div>
        </div>
        <select
          on:change={handleTypeChange}
          class="sm:ml-10 mt-5 sm:mt-0 w-full sm:w-auto block rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          {#if questionTypes}
            {#each questionTypes as { type, text }}
              <option value={type} selected={question.type === type}>
                {text}
              </option>
            {/each}
          {/if}
        </select>
      </div>
      <!-- Question content -->
      <div>
        {#if questionComponent[currentType]}
          <svelte:component
            this={questionComponent[question.type]}
            {question}
          />
        {/if}
      </div>
    </div>
  </button>
</li>

const debounce = <T extends (...args: any[]) => any>(callback: T, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>): void => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export default debounce;

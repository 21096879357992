<script lang="ts">
    import {type Notification} from "@/stores/notifications_store.js";

    export let notification: Notification;

    const colorMap = {
        success: 'order-emerald-100',
        error: 'border-rose-100',
        warn: 'border-amber-100',
        info: 'border-blue-200',
    }
</script>

<div
        id={notification.id}
        class="relative w-1/2 lg:w-1/3 rounded-lg border p-4 [&:has(svg)]:pl-11 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4  bg-background text-foreground { colorMap[notification.type] || 'text-foreground border [&>svg]:text-foreground' }"
>
    <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-5"
    >
        <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
        />
    </svg>
    <h5 class="mb-1 font-medium leading-none tracking-tight">
        {notification.title}
    </h5>
    <div class="text-sm [&_p]:leading-relaxed text-foreground">
        {@html notification.message}
    </div>
</div>

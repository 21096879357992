import {type ActionEvent, Controller} from '@hotwired/stimulus';

export default class ConfirmationsShowSelectController extends Controller {
  static values = {
    url: String,
  };

  declare urlValue: string;

  cart = {
    labors: [] as string[],
    spares: [] as string[],
  };

  public selectLabor({ currentTarget, params }: ActionEvent) {
    if (this.cart.labors.includes(params.id)) {
      this.removeFromCollection(this.cart.labors, params.id);
      this.onLaborUnselect(currentTarget as HTMLElement);
      return;
    }
    this.cart.labors.push(params.id);
    this.onLaborSelect(currentTarget as HTMLElement);
  }

  public selectSpare({ currentTarget, params }: ActionEvent) {
    if (this.cart.spares.includes(params.id)) {
      this.removeFromCollection(this.cart.spares, params.id);
      this.onLaborUnselect(currentTarget as HTMLElement);
      return;
    }
    this.cart.spares.push(params.id);
    this.onLaborSelect(currentTarget as HTMLElement);
  }

  public submit() {
    console.log(this.cart)

    // fetch(this.urlValue, {
    //   method: 'PATCH',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
    //       .getAttribute('content'),
    //   },
    //   body: JSON.stringify({
    //     confirmation: formattedPayload,
    //   }),
    // });
  }

  public submitNone() {
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')!
          .getAttribute("content") as string,
      },
      body: JSON.stringify({
        confirmation: { action: "deny" },
      }),
    });
  }

  public submitAll() {
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')!
          .getAttribute("content") as string,
      },
      body: JSON.stringify({
        confirmation: { action: "accept" },
      }),
    });
  }

  private onLaborSelect(target: HTMLElement) {
    target.classList.replace("bg-gray-50", "bg-emerald-50");
    target.classList.replace("hover:bg-gray-200", "hover:bg-emerald-50");

    target.classList.add("md:translate-x-full");
  }

  private onLaborUnselect(target: HTMLElement) {
    target.classList.replace("bg-emerald-50", "bg-gray-50");
    target.classList.replace("hover:bg-emerald-50", "hover:bg-gray-200");

    target.classList.remove("md:translate-x-full");
  }

  private removeFromCollection(collection: string[], id: string) {
    collection.splice(collection.indexOf(id), 1);
  }
}

import {type ActionEvent, Controller} from '@hotwired/stimulus';

export default class extends Controller {
  to({ target, params }: ActionEvent) {
    const el = target as HTMLElement;

    if (el.dataset.role === 'override') return;

    window.location.href = params.path;
  }
}

import debounce from "@/util/debounce.js";
import formatPlate from "@/util/formatPlate.js";
import FiltersController from "@/shared/filters_controller.js";

export default class WorkOrdersIndexFiltersController extends FiltersController {
  declare debouncedPlateSearch: (plate: string) => void;

  connect() {
    super.connect();

    this.debouncedPlateSearch = debounce(
        (plate: string) =>
            this.setSearchParameterAndVisit(
                "plate",
                plate.replace(/[^a-zA-Z0-9]/g, "")
            ),
        550
    );
  }

  plateSearch(event: Event) {
    const target = event.target as HTMLInputElement;
    target.value = formatPlate(target.value);
    this.debouncedPlateSearch(target.value);
  }

  statusSelect({ currentTarget }: Event) {
    this.setSearchParameterAndVisit('status', (currentTarget as HTMLSelectElement).value);
  }
}

import {type ActionEvent, Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spare', 'child'];

  declare spareTargets: HTMLElement[];

  connect() {
    this.calculateSpareTotals();
  }

  setTotal({ currentTarget }: { currentTarget: ActionEvent }) {
    this.calculateSpareTotal(
      currentTarget.closest('[data-manager--labors--edit--form-target="spare"]')
    );
  }

  removeChildLabor({ currentTarget }: ActionEvent) {
    const child = currentTarget.closest(
      '[data-manager--labors--edit--form-target="child"]'
    );
    child.classList.add('hidden');
    child.querySelector('[data-role="destroy"]').value = true;
  }

  removeLaborSpare({ currentTarget }: ActionEvent) {
    const spare = currentTarget.closest(
      '[data-manager--labors--edit--form-target="spare"]'
    );
    spare.classList.add('hidden');
    spare.querySelector('[data-role="destroy"]').value = true;
  }

  calculateSpareTotals() {
    this.spareTargets.forEach((spare) => {
      this.calculateSpareTotal(spare);
    });
  }

  calculateSpareTotal(spare: HTMLElement) {
    const quantity = spare.querySelector('[data-role="spare_quantity"]').value;
    const unitPrice = parseFloat(
      spare.querySelector('[data-role="spare_unit_price"]').dataset.value
    );
    const total = spare.querySelector('[data-role="total"]');

    if (total.dataset.format === '%u %n') {
      total.textContent = `${total.dataset.symbol} ${(quantity * unitPrice).toFixed(2)}`;
    } else {
      total.textContent = quantity * unitPrice;
    }
  }
}

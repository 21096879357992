import { Controller } from '@hotwired/stimulus';
import debounce from '@/util/debounce.ts';

export default class extends Controller {
  static values = {
    payload: Object,
    xImageUrl: String,
  };

  declare payloadValue: Record<string, { x: number; y: number }[]>;
  declare xImageUrlValue: string;

  images: string[] = ['front', 'left', 'up', 'right', 'back'];

  debouncedRender: () => void = debounce(() => {
    this.images.forEach((image) => this.renderIconsOn(image));
  }, 500);

  connect() {
    window.addEventListener('resize', () => {
      this.images.forEach((image) => this.removeIconsOn(image));
      this.debouncedRender();
    });
  }

  renderDamages() {
    this.images.forEach((image) => this.renderIconsOn(image));
  }

  renderIconsOn(image: string) {
    const container = document.getElementById(`${image}ImageContainer`);

    const { offsetWidth: width, offsetHeight: height } = container;

    this.payloadValue[image].forEach((position) =>
      this.insertIconAt(image, position, container, width, height)
    );
  }

  removeIconsOn(image: string) {
    const container = document.getElementById(`${image}ImageContainer`);

    const icons: NodeListOf<HTMLImageElement> = container.querySelectorAll(
      '[data-role="damage-icon"]'
    );
    icons.forEach((icon) => icon.remove());
  }

  insertIconAt(
    image: string,
    position: {
      x: number;
      y: number;
    },
    container: HTMLElement,
    width: number,
    height: number
  ) {
    const iconForVerticalImage = ['front', 'back'].includes(image);
    const iconWidth = width * (iconForVerticalImage ? 0.1 : 0.06);
    const xIcon = document.createElement('img');
    xIcon.src = this.xImageUrlValue;
    xIcon.setAttribute('data-role', 'damage-icon');
    xIcon.style.position = 'absolute';
    xIcon.style.width = `${iconWidth}px`;
    xIcon.style.left = `${position.x * width - iconWidth / 2}px`;
    xIcon.style.top = `${position.y * height - iconWidth / 2}px`;
    container.appendChild(xIcon);
  }
}

import { writable } from 'svelte/store';

export type Notification = {
  id: string;
  type: string;
  title: string;
  message: string;
}

const { subscribe, update } = writable([] as Notification[]);


const store = {
  add: (type : string, title : string, message : string) => {
    const id = crypto.randomUUID();

    update((notifications) => {
      return [...notifications, { id, type, title, message } as Notification];
    });

    setTimeout(() => {
      store.remove(id);
    }, 8000);
  },
  remove: (id : string) => {
    update((notifications) => {
      return notifications.filter((notification) => notification.id !== id);
    });
  },
};

export { subscribe, store };

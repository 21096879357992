import { writable } from 'svelte/store';

// Initial writable stores
export const types = writable({});

export type Question = {
  index: number;
  id: string;
  question: string;
  required: boolean;
  type: string;
  sectionId?: string;
  options?: string[];
};

export type Section = {
    index: number;
    id: string;
    title: string;
    description: string;
    goTo: string;
}

export type Report = {
  sections: Section[];
  questions: Question[];
  lastType: string;
};
export const focusedStore = writable(null);
const reportStore = writable<Report>();

// Destructure update from reportStore
const { update } = reportStore;

// Utility function to generate a new section object
const newSection = (index : number) => ({
  index: index,
  id: crypto.randomUUID(),
  title: null,
  description: null,
  goTo: 'next',
});

// Sections store
const sections = {
  add: () => {
    let section : Section;

    update((store) => {
      const nextIndex =
        store.sections.length > 0
          ? store.sections[store.sections.length - 1].index + 1
          : 0;

      section = newSection(nextIndex);

      if (store.sections.length > 0) {
        // Append the new section to existing sections
        return {
          ...store,
          sections: [...store.sections, section],
        };
      } else {
        // Create initial sections and update questions with sectionId
        const initialSections = [section, newSection(nextIndex + 1)];
        const updatedQuestions = store.questions.map((q) => ({
          ...q,
          sectionId: section.id,
        }));

        return {
          ...store,
          sections: initialSections,
          questions: updatedQuestions,
        };
      }
    });

    return section.id;
  },
  edit: (id : string, payload : Partial<Section>) => {
    update((store) => ({
      ...store,
      sections: store.sections.map((s) =>
        s.id === id ? { ...s, ...payload } : s
      ),
    }));
  },
  remove: (id : string) => {
    update((store) => {
      return {
        ...store,
        sections: store.sections.filter((s) => s.id !== id),
        questions: store.questions.filter((q) => q.sectionId !== id),
      };
    });
  },
};

// Questions store
const questions = {
  add: (sectionId = null) => {
    let question = null;

    update((store) => {
      let nextIndex : number;

      if (sectionId) {
        const maxIndexInSection = store.questions
          .filter((q) => q.sectionId === sectionId)
          .reduce((acc, q) => (q.index > acc ? q.index : acc), -1);

        nextIndex = maxIndexInSection >= 0 ? maxIndexInSection + 1 : 0;
      } else {
        // Calculate next index based on the highest index in the entire array
        const maxIndex = store.questions.reduce(
          (acc, q) => (q.index > acc ? q.index : acc),
          -1
        );
        nextIndex = maxIndex >= 0 ? maxIndex + 1 : 0;
      }

      question = {
        id: crypto.randomUUID(),
        index: nextIndex,
        question: null,
        required: false,
        type: store.lastType || 'short_answer',
        ...(sectionId && { sectionId }),
      };
      return {
        ...store,
        questions: [...store.questions, question],
      };
    });

    return question.id;
  },
  edit: (id : string, payload : Partial<Question>) => {
    update((store) => ({
      ...store,
      questions: store.questions.map((q) =>
        q.id === id ? { ...q, ...payload } : q
      ),
    }));
  },
  remove: (id : string) => {
    update((store) => ({
      ...store,
      questions: store.questions.filter((q) => q.id !== id),
    }));
  },
};

// Export necessary variables and functions
export { sections, questions };
export const { set, subscribe } = reportStore; // Export set and subscribe from reportStore

import { Controller } from "@hotwired/stimulus";
import anime from "animejs";

export default class extends Controller {
    static targets = ["backdrop", "dialog"];

    declare backdropTarget: HTMLElement;
    declare dialogTarget: HTMLElement;

    open() {
        this.backdropTarget.classList.remove("hidden");
        this.dialogTarget.classList.remove("hidden");
        anime({
            targets: this.backdropTarget,
            easing: 'easeOutExpo',
            opacity: [0, 1],
            duration: 300,
        });
        anime({
            targets: this.dialogTarget,
            easing: 'easeOutExpo',
            scale: [0.95, 1],
            translateY: ['-50%', '-50%'],
            translateX: ['-50%', '-50%'],
            opacity: [0, 1],
            duration: 300,
        });
    }
    close() {
        anime({
            targets: this.backdropTarget,
            easing: 'easeInExpo',
            opacity: [1, 0],
            duration: 300,
        });
        anime({
            targets: this.dialogTarget,
            easing: 'easeInExpo',
            scale: [1, 0.95],
            opacity: [1, 0],
            duration: 300,
        });
        setTimeout(() => {
            this.backdropTarget.classList.add("hidden");
            this.dialogTarget.classList.add("hidden");
        }, 350);
    }

    backdropClose(e : MouseEvent) {
        if (e.currentTarget !== e.target) return;
        this.close();
    }
}

<script lang="ts">
    import { onMount } from "svelte";
    import {
        subscribe,
        type Notification as NotificationType,
    } from "@/stores/notifications_store.js";
    import Notification from "@/components/shared/Notification.svelte";

    let notifications: NotificationType[] = [];

    onMount(() => {
        subscribe((value) => (notifications = value));
    });
</script>

{#each notifications as notification}
    <Notification {notification} />
{/each}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  declare menuTarget: HTMLElement;

  connect() {
    document.addEventListener('click', (event) => this.outsideClick(event));
  }
  disconnect() {
    document.removeEventListener('click', this.outsideClick);
  }
  toggle() {
    this.menuTarget.classList.toggle('hidden');
  }

  outsideClick(event: MouseEvent) {
    if (!this.element.contains(event.target as Node)) {
      this.menuTarget.classList.add('hidden');
    }
  }
}

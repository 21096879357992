import { Controller } from '@hotwired/stimulus';
import Form from '@/components/manager/reports/edit/Form.svelte';
import {
  set,
  types,
  questions,
  sections,
  type Report
} from '@/stores/manager_reports_store.ts';

export default class extends Controller {
  static values = { payload: Object, supportedTypes: Array };

    declare payloadValue: Report;
    declare supportedTypesValue: string[];

  initialize() {
    types.set(this.supportedTypesValue);
    set({ ...this.payloadValue });
    new Form({
      target: this.element,
      intro: true,
    });
  }

  connect() {
    // questions.add();
    // questions.add();
    // sections.add();
    // sections.add();
    // const test = sections.add();
    // questions.add(test);
    // questions.add(test);
    // questions.add(test);
    // const test2 = questions.add(test);
    // questions.remove(test2);
  }
}

<script lang="ts">
  import Section from '@/components/manager/reports/edit/Section.svelte';
  import Question from '@/components/manager/reports/edit/Question.svelte';
  import { subscribe, questions, focusedStore as focus, type Report } from '@/stores/manager_reports_store.ts';
  import { setContext, onMount } from 'svelte';
  import { get } from 'svelte/store'

  let report : Report;

  subscribe((store) => (report = store));

  onMount(() => {
    focus.subscribe((store) => {
      console.log(store);
    })
  })

  const setFocusedItem = (target : HTMLElement) => {
    if (get(focus) === target) return;

    focus.set(target);
  }

  const handleAddQuestionClick = () => {
    questions.add();
  }

  const submitReport = () => {
    console.log(JSON.stringify(report));
  }

  setContext('focusedItem', setFocusedItem)
</script>

<pre class="hidden pt-10">
    { JSON.stringify(report, null, 2) }
</pre>

<div class="mx-auto max-w-3xl">
  <div class="mx-auto max-w-3xl mt-10">
    <form>
      <ul>
        {#if report.sections.length !== 0}
        {#each report.sections as section, i}
          {@const questions = report.questions.filter(
            (question) => question.sectionId === section.id
          )}
          <Section key={i} {section} {questions} />
        {/each}
      {:else}
        <ul>
          {#each report.questions as question, i}
            <Question {question} />
          {/each}
        </ul>
      {/if}
      </ul>
    </form>
  </div>
</div>
<div
  class="w-full fixed bottom-0 bg-white h-16 flex items-center px-10 lg:hidden"
>
  <button on:click={handleAddQuestionClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-7 h-7"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  </button>
</div>
<div class="hidden">
  <div
    class="flex-col items-center hidden lg:flex text-gray-400 shadow-xl w-14 bg-white rounded-lg py-5 absolute animate-opacity-in -right-[80px]"
  >
    <button
      type="button"
      class="mb-4 hover:scale-105"
      on:click={handleAddQuestionClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </button>
    <button type="button" class="mb-4 hover:scale-105">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8"
        viewBox="0 0 256 256"
      >
        <path
          fill="currentColor"
          d="M208 136H48a16 16 0 0 0-16 16v40a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-40a16 16 0 0 0-16-16m0 56H48v-40h160zm0-144H48a16 16 0 0 0-16 16v40a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16m0 56H48V64h160z"
        />
      </svg>
    </button>
  </div>
</div>

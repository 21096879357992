import { Controller } from '@hotwired/stimulus';
import anime from 'animejs';

export default class extends Controller {
  static targets = ['nav', 'sidebar', 'backdrop', 'menu', 'button'];

  declare navTarget: HTMLElement;
    declare sidebarTarget: HTMLDivElement;
    declare backdropTarget: HTMLElement;
    declare menuTarget: HTMLElement;
    declare buttonTarget: HTMLElement;

  openSidebar() {
    this.navTarget.classList.remove('hidden');
    anime({
      targets: [this.backdropTarget, this.buttonTarget],
      easing: 'linear',
      opacity: [0, 1],
      duration: 300,
    });
    anime({
      targets: this.sidebarTarget,
      easing: 'easeInOutQuad',
      translateX: ['-100%', '0%'],
      duration: 300,
    });
  }
  closeSidebar() {
    anime({
      targets: [this.backdropTarget, this.buttonTarget],
      easing: 'linear',
      opacity: [1, 0],
      duration: 300,
    });

    anime({
      targets: this.sidebarTarget,
      easing: 'easeInOutQuad',
      translateX: ['0%', '-100%'],
      duration: 300,
    });

    setTimeout(() => {
      this.navTarget.classList.add('hidden');
    }, 600);
  }
  openMenu() {
    this.menuTarget.classList.remove('hidden');
  }

  backdropCloseSidebar(event: MouseEvent) {
    if (event.currentTarget !== event.target) return;
    this.closeSidebar();
  }

  closeMenu(event: MouseEvent) {
    if (
      event.target !== this.menuTarget &&
      !(event.target as HTMLElement)?.closest('#user-menu-button')
    ) {
      this.menuTarget.classList.add('hidden');
    }
  }
}

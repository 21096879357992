import debounce from "@/util/debounce.js";
import formatPlate from "@/util/formatPlate.js";
import FiltersController from "@/shared/filters_controller.js";

export default class extends FiltersController {
  declare debouncedPlateSearch: (plate: string) => void;

  connect() {
    super.connect();

    this.debouncedPlateSearch = debounce(
      (plate: string) =>
        this.setSearchParameterAndVisit(
          "plate",
          plate.replace(/[^a-zA-Z0-9]/g, "")
        ),
      550
    );
  }

  //? Actions
  plateSearch(event: Event) {
    const target = event.target as HTMLInputElement;
    target.value = formatPlate(target.value);
    this.debouncedPlateSearch(target.value);
  }

  colorSelect({ currentTarget }: Event) {
    this.setSearchParameterAndVisit(
      "color",
      (currentTarget as HTMLSelectElement).value
    );
  }

  brandSelect({ currentTarget }: Event) {
    this.setSearchParameterAndVisit(
      "brand",
      (currentTarget as HTMLSelectElement).value
    );
  }

  modelSelect({ currentTarget }: Event) {
    this.setSearchParameterAndVisit(
      "model",
      (currentTarget as HTMLSelectElement).value
    );
  }

  //? End Actions
}

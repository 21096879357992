const formatPlate = (plate: string) => {
  plate = plate.toUpperCase().replace(/[^A-Z0-9]/g, '');

  if (plate.length > 3) {
    plate = plate.slice(0, 3) + '-' + plate.slice(3, 6);
  } else {
    plate = plate.slice(0, 3);
  }

  return plate;
};

export default formatPlate;
